<template>
  <!-- <keep-alive> -->
  <div :class="['header', headerWhite === true ? 'white' : 'bgHoverShow']">
    <div class="logo" @click="goHome">
      <img src="../assets/img/component/logo@2x.png" alt="" />
    </div>
    <ul class="nav">
      <li :class="$route.path === item.path ? 'active' : ''" @click="changeTab(index)" v-for="(item, index) in tabData" :key="index">{{ item.name }}</li>
    </ul>
  </div>
  <!-- </keep-alive> -->
</template>
<script>
export default {
  name: 'Header',
  props: {
    headerWhite: {
      type: Boolean,
      require: true
    }
  },
  data() {
    return {
      tabData: [
        { name: '主页', path: '/home', status: false },
        { name: '产品服务', path: '/service', status: false },
        { name: '员工', path: '/member', status: false },
        { name: '关于我们', path: '/aboutUs', status: false },
        { name: '加入我们', path: '/contactUs', status: false }
      ],
      path: ''
    }
  },
  created() {},
  methods: {
    // 路由跳转
    changeTab(i) {
      //   判断点击的是否为当前 url
      this.path = this.tabData[i].path
      if (this.$route.path === this.path) return // 相同地址不能跳转
      this.$router.push(this.path)
    },
    // 回主页
    goHome() {
      if (this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home' })
      } else {
        window.scroll(0, 1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  position: relative;
  min-width: 1440px;
  height: 60px;
  background: rgba(255, 255, 255, 0.1);
  .logo {
    position: absolute;
    top: 11px;
    left: 131px;
    width: 108px;
    height: 38px;
    z-index: 999;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav {
    position: absolute;
    right: 130px;
    li {
      position: relative;
      margin-left: 72px;
      float: left;
      font-weight: 600;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 60px;
      color: #010c29;
      cursor: pointer;
    }
    .active {
      color: #4671ff;
    }
    .active::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px;
      width: 100%;
      background-color: #4671ff;
    }

    // 动画-鼠标移动往外扩散
    li::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      height: 4px;
      width: 100%;
      background-color: #4671ff;
      transform: scaleX(0);
    }

    li:hover::after {
      transform: scaleX(1);
    }

    li:hover::after {
      animation: move 0.5s;
    }

    @keyframes move {
      0% {
        transform: scaleX(0);
      }
      100% {
        transform: scaleX(1);
      }
    }
  }
}
.bgHoverShow {
  transition: all 1s;
}
.bgHoverShow:hover {
  background: rgba(255, 255, 255, 0.5);
}
.white {
  background-color: #ffffff;
}
</style>
