<template>
  <div class="wrap">
    <div class="header" ref="headerRef">
      <headerTop></headerTop>
    </div>
    <div class="swiper swiper-container">
      <div ref="swiperRef" class="swiper-wrapper">
        <div class="swiper-slide stop-swiping" v-for="(item, i) in swiperImg" :key="i"><img :src="item" alt="" /></div>
        <!-- <div class="swiper-slide stop-swiping"><img src="" alt="" /></div>
        <div class="swiper-slide stop-swiping"><img src="" alt="" /></div> -->
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>
    <div ref="replaceTab"></div>
    <!-- tab栏切换 -->
    <ul :class="['tab', tabFlag == true ? 'addPosition' : '']" ref="tabRef">
      <li v-for="(item, index) in tabBar" :key="index" :class="{ tab_active: item.status }" @click="changeTab(index)">
        <img :src="item.src0" alt="" v-show="item.state == 0" />
        <img :src="item.src1" alt="" v-show="item.state == 1" />
        <img :src="item.src2" alt="" v-show="item.state == 2" />{{ item.name }}
      </li>
    </ul>
    <!-- tab 项 -->
    <div class="page2 contanier">
      <!-- 智能核保 -->
      <div class="intelligence">
        <div class="left">
          <h3>
            <span>{{ nowTab.name1 }}</span
            ><span>{{ nowTab.name2 }}</span>
          </h3>
          <i></i>
          <p>
            {{ nowTab.text }}
          </p>
        </div>
        <div class="right">
          <!-- <video :src="nowTab.videoURL" autoplay muted loop></video> -->
          <div id="player"></div>
          <!-- <div id="player2" v-show="true"></div> -->
          <!-- <div id="player3" v-show="false"></div> -->
        </div>
      </div>
    </div>
    <!-- 智能核保 -->
    <intelligentUnderwriting v-show="tabBar[0].status"></intelligentUnderwriting>
    <!-- 智慧理赔 -->
    <smartClaims v-show="tabBar[1].status"></smartClaims>
    <!-- 健康管理 -->
    <healthManagement v-show="tabBar[2].status"></healthManagement>
    <pageFooter @goAnchor="goAnchor"></pageFooter>
  </div>
</template>

<script>
// 导入swiper插件
import Swiper from 'swiper'
import '../../node_modules/swiper/dist/css/swiper.css'
import '../../node_modules/swiper/dist/js/swiper.min.js'
import headerTop from '@/components/header.vue'
import pageFooter from '@/components/footer.vue'
import healthManagement from '@/components/healthy_management.vue'
import smartClaims from '@/components/smart_claims.vue'
import intelligentUnderwriting from '@/components/intelligent_underwriting.vue'
export default {
  name: 'Service',
  components: {
    headerTop,
    pageFooter,
    healthManagement,
    smartClaims,
    intelligentUnderwriting
  },
  data() {
    return {
      vodPlayerJs: 'https://player.polyv.net/script/player.js',
      vid: '226f52cfe41adb9a12c3ea58344584e0_2',
      vid1: '226f52cfe41adb9a12c3ea58344584e0_2',
      vid2: '226f52cfe410a44805e383195ae7ba76_2',
      vid3: '226f52cfe4df17b6f7847a2afd7caec2_2',
      swiperImg: [require('../assets/img/service/swiperOne.png'), require('../assets/img/service/swiperTwo.png'), require('../assets/img/service/swiperThree.png')],
      swiperImgIndex: 0,
      tabBar: [
        {
          name: '智能核保',
          src0: require('../assets/icon/service/tab_intelligence0.png'),
          src1: require('../assets/icon/service/tab_intelligence1.png'),
          src2: require('../assets/icon/service/tab_intelligence2.png'),
          state: 1,
          status: true
        },
        {
          name: '智慧理赔',
          src0: require('../assets/icon/service/tab_wisdom0.png'),
          src1: require('../assets/icon/service/tab_wisdom1.png'),
          src2: require('../assets/icon/service/tab_wisdom2.png'),
          state: 0,
          status: false
        },
        {
          name: '健康管理',
          src0: require('../assets/icon/service/tab_healthy0.png'),
          src1: require('../assets/icon/service/tab_healthy1.png'),
          src2: require('../assets/icon/service/tab_healthy2.png'),
          state: 0,
          status: false
        }
      ],
      tabData: [
        {
          name1: '智能核保',
          name2: '系统概述',
          text: '睿见智能核保系统，包括保险产品仓库、核保平台、产品数据分析3个平台。保险产品仓库主要包括产品条款、核保要点、保费预测和出单系统；核保平台包括预核保和保后核保2个模式；产品数据分析包括产品精算、个人医疗管理和个人核保评估4个功能。',
          // videoURL: require('../assets/video/service_intelligent_underwriting.mp4'),
          vid: '226f52cfe41adb9a12c3ea58344584e0_2'
        },
        {
          name1: '智慧理赔',
          name2: '系统概述',
          text: '全流程智能理赔，助力健康险平台全面提速。智能理赔平台，通过智能识别的方式完成理算信息的提取，录入自动化解决方案；以标准化、自动化的方式替代人工完成票据信息处理作业；大幅提升核赔理算效率。',
          // videoURL: require('../assets/video/service_smart_claims.mp4'),
          vid: '226f52cfe410a44805e383195ae7ba76_2'
        },
        {
          name1: '健康管理',
          name2: '平台概述',
          text: '健康管理平台主要针对慢性病患者人群，分类管理15种慢病疾病及并发症患者，根据患者情况分别推送不同的健康指导和建议，针对首次确诊的慢性病患者给予建立电子健康档案，提供居家指标监测服务及复诊管理服务，提供权威的健康生活小帖士，推送相关医疗健康常识，从而提高患者的依从性。',
          // videoURL: require('../assets/video/service_health_management.mp4'),
          vid: '226f52cfe4df17b6f7847a2afd7caec2_2'
        }
      ],
      nowTab: {},
      scrollTop: '', // 被卷去的距离
      headerHight: '', // header高度
      tabOffsetTop: '', // tab 栏距离头部最大的距离
      // bigDistance: ''
      tabFlag: false,
      // srcFlag: false,
      num: 0 // 存放的是第几张图片
    }
  },
  computed: {
    bigDistance() {
      return this.scrollTop - this.tabOffsetTop + this.headerHight
    }
  },
  watch: {
    bigDistance(val) {
      // console.log(val)
      if (val >= 0) {
        // console.log(111111111111)
        this.tabFlag = true
      } else {
        // console.log(222222222)
        this.tabFlag = false
      }
    },
    tabFlag(val) {
      // console.log('tabflag再变化')
      if (val) {
        this.tabBar.forEach((item) => {
          item.state = 2
        })
      } else {
        this.tabBar.forEach((item) => {
          // console.log('执行')
          if (item.status) {
            item.state = 1
          } else {
            item.state = 0
          }
        })
      }
    }
  },
  created() {
    this.nowTab = this.tabData[0]
    window.scroll(0, 1)
    // this.vid = ''
    // if (this.player) {
    //   this.player.destroy()
    // }

    // this.loadPlayerScript(this.loadPlayer)
  },
  mounted() {
    // this.loadPlayerScript2(this.loadPlayer2)
    // 其他页面跳转到指定tab
    if (this.$route.params.state === 1) {
      // 其他换tab
      // console.log('切换tab')
      this.$refs.tabRef.scrollIntoView(true)
      this.changeTab(this.$route.params.index)
    } else {
      // console.log('从其他页面非footer进入')
      this.changeTab(0)
    }
    this.headerHight = this.$refs.headerRef.clientHeight
    // console.dir(this.$refs.headerRef)
    this.tabOffsetTop = this.$refs.tabRef.offsetTop
    // console.dir(this.$refs.tabRef.offsetTop)
    // 检测滚轮事件
    window.addEventListener('scroll', this.socrllChange)
    // 轮播图事件
    // this.carouselChange()
    this.$nextTick(() => {
      this._initSwiper()
    })
  },
  methods: {
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script')
        myScript.setAttribute('src', this.vodPlayerJs)
        myScript.onload = callback
        document.body.appendChild(myScript)
      } else {
        callback()
      }
    },

    loadPlayer() {
      const polyvPlayer = window.polyvPlayer
      this.player = polyvPlayer({
        wrap: '#player',
        width: '100%',
        height: '100%',
        autoplay: true,
        // skinLocation: 0,
        volume: 0,
        loop: true,
        vid: this.vid
      })
    },
    // 页面内-切换tab
    goAnchor(val) {
      this.tabBar.forEach((item) => {
        item.state = 2
        item.status = false
      })
      this.tabBar[val].status = true
      this.nowTab = this.tabData[val]
      this.$refs.replaceTab.scrollIntoView(true)
      this.vid = this.tabData[val].vid
      if (this.player) {
        this.player.destroy()
      }
      this.loadPlayerScript(this.loadPlayer)
    },
    // 轮播图设置
    _initSwiper() {
      this.mySwiper = new Swiper('.swiper-container', {
        direction: 'horizontal',
        loop: true,
        initialSlide: this.swiperImgIndex,
        autoplay: 3000, // 自动播放
        // 禁止拖动
        noSwiping: true,
        noSwipingClass: 'stop-swiping',
        // 如果需要分页器
        pagination: '.swiper-pagination'

        // 如果需要前进后退按钮
        // nextButton: '.swiper-button-next',
        // prevButton: '.swiper-button-prev'
      })
    },
    // 页面滑动
    socrllChange() {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop // 被卷去的距离
      // console.log(this.scrollTop)
    },
    // 切换 tab
    changeTab(i) {
      // 已经定位
      if (this.tabFlag) {
        // console.log('已定位')
        this.tabBar.forEach((item) => {
          item.status = false
        })
        // console.log('执行动画')
        this.animate(window, 0)
        this.swiperImgIndex = i
      } else {
        // 未定位
        // console.log('未定位')
        this.tabBar.forEach((item) => {
          item.state = 0
          item.status = false
        })
        this.tabBar[i].state = 1
      }
      this.vid = this.tabData[i].vid
      if (this.player) {
        this.player.destroy()
      }
      this.loadPlayerScript(this.loadPlayer)
      this.tabBar[i].status = true
      this.nowTab = this.tabData[i]
    },
    // 动画函数
    animate(obj, target, callback) {
      // console.log(callback);  callback = function() {}  调用的时候 callback()
      // 先清除以前的定时器，只保留当前的一个定时器执行
      clearInterval(obj.timer)
      obj.timer = setInterval(function () {
        // 步长值写到定时器的里面
        // 把我们步长值改为整数 不要出现小数的问题
        // var step = Math.ceil((target - obj.offsetLeft) / 10);
        var step = (target - window.pageYOffset) / 10
        step = step > 0 ? Math.ceil(step) : Math.floor(step)
        if (window.pageYOffset === target) {
          // 停止动画 本质是停止定时器
          clearInterval(obj.timer)
          // 回调函数写到定时器结束里面
          // if (callback) {
          //     // 调用函数
          //     callback();
          // }
          callback && callback()
        }
        // 把每次加1 这个步长值改为一个慢慢变小的值  步长公式：(目标值 - 现在的位置) / 10
        // obj.style.left = window.pageYOffset + step + 'px';
        window.scroll(1, window.pageYOffset + step)
      }, 15)
    },
    destroyed() {
      if (this.player) {
        this.player.destroy()
      }
    }
  }
}
</script>

<style lang="less" scoped>
#player,
#player2,
#player3 {
  width: 100%;
  height: 100%;
}
.wrap {
  width: 100%;
  overflow-x: hidden;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #fff;
  z-index: 999;
}
.header:hover {
  background-color: #fff;
}
.swiper {
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: 558px;
  .swiper-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 500%;
    height: 100%;
    .swiper-slide {
      float: left;
      width: 1440px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.tab {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 60px;
  z-index: 90;
  transition: all 0.5s;
  li {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    line-height: 60px;
    color: #010f36;
    cursor: pointer;
    transition: all 0.5s;
    img {
      margin-right: 6px;
      width: 24px;
      height: 24px;
    }
  }
  .tab_active {
    color: #00259f;
    border-bottom: 2px solid #00259f;
  }
}
.addPosition {
  min-width: 1423px;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: rgba(33, 38, 53, 0.85);
  li {
    color: #ffffff;
    opacity: 0.7;
  }
  .tab_active {
    opacity: 1;
    color: #fff;
    border: none;
  }
}
.page2 {
  width: 100%;
  height: 667px;
  background: url('../assets/img/service/servicebg_page2@2x.png');
  background-size: cover;
  overflow: hidden;
  box-sizing: border-box;
  .intelligence {
    display: flex;
    justify-content: space-between;
    margin-top: 176px;
    width: 100%;
    height: 427px;
    .left {
      width: 322px;
      height: 100%;
      h3 {
        display: flex;
        flex-direction: column;
        width: 112px;
        height: 112px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 550;
        color: #010c29;
        line-height: 56px;
      }
      i {
        display: block;
        margin-top: 82px;
        width: 28px;
        height: 1px;
        background: #788398;
      }
      p {
        margin-top: 28px;
        width: 322px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #788398;
        line-height: 28px;
      }
    }
    .right {
      width: 759px;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
