<template>
  <div class="wrap">
    <div class="claim_process contanier">
      <h3 class="title">打造全流程智能理赔服务</h3>
      <!-- 智能识别 -->
      <div class="item pOne">
        <div class="left">
          <img src="../assets/icon/service/number01.png" alt="" class="num" />
          <span class="tit">智能识别</span>
          <p>
            外勤团队在完成收单后（或手机端直接上传），将图片数据扫描电子化上传至系统中，系统将会通过人工智能识别技术自动对案件图片进行分类、版本分析、信息点提取、数据识别、核查分析、入库，然后可根据需求进行导入导出操作，与客户核心系统无缝衔接。
          </p>
        </div>
        <div class="right"><img src="../assets/img/service/intelligent_recognition.png" alt="" /></div>
      </div>
      <!-- 智能理算 -->
      <div class="item pTwo">
        <div class="left">
          <img src="../assets/icon/service/number02.png" alt="" class="num" />
          <span class="tit">智能理算</span>
          <p>在健康险理算中，医疗票据识别引擎与智能理算规则的无缝连接，使理算作业从信息录入到结果输出实现全面自动化，智能规则匹配，拟人类的规则判断。可以在无人工参与下理算。</p>
        </div>
        <div class="right"><img src="../assets/img/service/intelligent_adjustment.png" alt="" /></div>
      </div>
      <!-- 智能风控 -->
      <div class="item pThree">
        <div class="left">
          <img src="../assets/icon/service/number03.png" alt="" class="num" />
          <span class="tit">智能风控</span>
          <p>
            根据定制的规则筛查保单，进行在线核保（如果遇到“有问题”的订单会转移到人工平台进行审核）。此阶段需要对投保人的不良信息进行筛查，对有过欺诈或失信行为的人加以拒保。另外可以建立风险定价模型，对于高风险客户通过提高保费增加欺诈或逆选择客户的成本。
          </p>
        </div>
        <div class="right"><img src="../assets/img/service/intelligent_risk_control.png" alt="" /></div>
      </div>
    </div>
    <div class="product_advantages">
      <h3 class="title">产品优势</h3>
      <div class="advantages_box">
        <ul>
          <li class="liOne">
            <div class="default"><img src="../assets/icon/service/advantage_one.png" alt="" /><span class="small_tit">高效的处理能力</span></div>
            <div class="hover">
              <div class="hover_tit">高效的处理能力</div>
              <p class="text">系统目前拥有每天处理十万张单证的能力，单张单证平台秒级自动处理，并可根据客户的实际业务负载进行扩充，实现更大的吞吐量。处理能力可达到6000件/小时。</p>
            </div>
          </li>
          <li class="liTwo">
            <div class="default"><img src="../assets/icon/service/advantage_two.png" alt="" /><span class="small_tit">精准的智能识别分类能力</span></div>
            <div class="hover">
              <div class="hover_tit">精准的智能识别分类能力</div>
              <p class="text">
                相较于传统TPA服务模式，需要场地、人员成本、培训成本、管理成本等，费用级别达到千万级。而中科睿见保险智能系统的成本构成为智能云管理、需求变更与开发和少量的核查人员成本，只需百万级就可实现高效的处理方式。
              </p>
            </div>
          </li>
          <li class="liThree">
            <div class="default"><img src="../assets/icon/service/advantage_three.png" alt="" /><span class="small_tit">费用合理</span></div>
            <div class="hover">
              <div class="hover_tit">费用合理</div>
              <p class="text">
                睿见科技在保险智能云上独立研发和提供了多种算法模块，“云+端”的形式对外提供多元化的能力输出：AI能力平台是一个融合了多种业务并对外输出多元化能力的平台。目前系统可以实现乱序单证自动分类、文字定位、版面分析抽取信息点、文字识别，整体识别结果准确率已达99.9%以上，每一个识别过程都相当于三个人工同时识别并相互校验，质量有所保障。
              </p>
            </div>
          </li>
          <li class="liFour">
            <div class="default"><img src="../assets/icon/service/advantage_four.png" alt="" /><span class="small_tit">数据的安全性有保障</span></div>
            <div class="hover">
              <div class="hover_tit">数据的安全性有保障</div>
              <p class="text">
                传统TPA数据安全性无保护措施，使得数据安全有很大的威胁。睿见科技保险智能系统采用NDA数据保护（智能云管理、数据闭环、无人工干预），使得数据的安全性有所保障。此外，系统支持定制化的API接口，采用256位及更高位数公-私钥流加密机制，该加密机制支持多种接入方式如VPN、专线、互联网等，支持同步、异步通信，支持常见通讯接口如Socket、WebSocket、MQ等，支持多种协议如http、https、ftp、sftp、RestfulAPI等，通过对密钥的独立存储，保证了加密和解密的密钥只可被用户可见，保障了密钥的生成、存储、管理、保护的可靠性，确保了加密传输的可靠性。
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: false
    }
  }
}
</script>

<style lang="less" scoped>
// .wrap {
//   height: 2000px;
//   background-color: aquamarine;
// }
.claim_process {
  height: 1725px;
  text-align: center;
  overflow: hidden;
  .title {
    margin-top: 136px;
    // width: 308px;
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 40px;
  }
  .item {
    margin-top: 152px;
    height: 320px;
    overflow: hidden;
    .left {
      float: left;
      width: 460px;
      text-align: left;
      .num {
        margin-top: 14px;
        display: block;
        width: 68px;
        height: 56px;
      }
      .tit {
        display: block;
        margin-top: 66px;
        width: 80px;
        height: 30px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #212632;
        line-height: 30px;
      }
      p {
        margin-top: 24px;
        width: 460px;
        height: 112px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #788398;
        line-height: 28px;
      }
    }
    .right {
      float: right;
      width: 560px;
      height: 320px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .pOne {
    margin-top: 120px;
  }
  .pTwo {
    .left {
      float: right;
    }
    .right {
      float: left;
    }
  }
}
.product_advantages {
  height: 1072px;
  text-align: center;
  background: url('../assets/img/service/product_advantages.png') no-repeat;
  background-size: cover;
  overflow: hidden;
  .title {
    margin: 100px auto 86px;
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 40px;
  }
  .advantages_box {
    margin: 0 auto;
    width: 1180px;
    overflow: hidden;
    ul {
      width: 1200px;
      overflow: hidden;
      li {
        position: relative;
        // display: inline-block;
        float: left;
        margin-bottom: 20px;
        margin-right: 20px;
        width: 580px;
        height: 354px;
        background-color: #ffffff;
        overflow: hidden;
        .default {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          // z-index: 9;
          img {
            margin: 108px auto 48px;
            display: block;
            width: 60px;
            height: 60px;
          }
          .small_tit {
            // width: 220px;
            height: 30px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 30px;
          }
        }

        .hover {
          position: absolute;
          top: 354px;
          left: 0;
          width: 580px;
          height: 354px;
          transition: all 0.5s;
          z-index: 9;
          .hover_tit {
            height: 80px;
            // width: 140px;
            // height: 30px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            line-height: 80px;
            color: #212632;
            border-top: 2px solid #8f98a9;
            background-color: #edf0f5;
            box-sizing: border-box;
          }
          .text {
            // margin: 0 auto;
            // width: 500px;
            padding: 0 40px;
            height: 274px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #788398;
            line-height: 28px;
            text-align: left;
            background-color: #ffffff;
            box-sizing: border-box;
          }
        }
      }
      li:hover {
        .hover {
          top: 0;
        }
      }
      .liOne {
        .default {
          background: url('../assets/img/service/advantage_onebg.png') no-repeat;
          background-size: 100% 100%;
        }
        .hover {
          .text {
            padding-top: 57px;
          }
        }
      }
      .liTwo {
        margin-right: 0;
        .default {
          background: url('../assets/img/service/advantage_twobg.png') no-repeat;
          background-size: 100% 100%;
        }
        .hover {
          .text {
            padding-top: 57px;
          }
        }
      }
      .liThree {
        .default {
          background: url('../assets/img/service/advantage_threebg.png') no-repeat;
          background-size: 100% 100%;
        }
        .hover {
          .text {
            padding-top: 64px;
          }
        }
      }
      .liFour {
        margin-right: 0;
        .default {
          background: url('../assets/img/service/advantage_fourbg.png') no-repeat;
          background-size: 100% 100%;
        }
        .hover {
          .text {
            padding-top: 11px;
          }
        }
      }
    }
  }
}
</style>
