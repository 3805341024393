<template>
  <div class="wrap">
    <!-- 专业团队 -->
    <div class="professional_team">
      <h3 class="titOne">专业团队</h3>
      <span class="titTwo">健康管理项目团队是一个国内医疗、保险信息化团队</span>
      <div class="content_box">
        <div class="item">
          <img src="../assets/img/service/hea_team_doctor.png" alt="" />
          <span class="title">专业医生团队</span>
          <p class="text">具有丰富的临床经验，制定个性化管理方案、居家监测指标危险因素预警及慢病康复指导等。通过收集病史对客户健康作出准确判断并给出建议，防控风险，为用户提供医疗的专业意见。</p>
        </div>
        <div class="item">
          <img src="../assets/img/service/hea_team_medicine.png" alt="" />
          <span class="title">医学专家顾问</span>
          <p class="text">对人群或个人健康和疾病进行监测、分析、评估以及健康维护和健康促进，对用户的日常健康进行管理，为用户提供健康管理方面的专业咨询。</p>
        </div>
        <div class="item">
          <img src="../assets/img/service/hea_tem_motion.png" alt="" />
          <span class="title">运动康复师</span>
          <p class="text">提供专业的症状运动康复方案，为客户提供康复方面的专业咨询</p>
        </div>
        <div class="item">
          <img src="../assets/img/service/hea_team_psychology.png" alt="" />
          <span class="title">心理咨询师</span>
          <p class="text">为用户提供心理方面的专业咨询</p>
        </div>
      </div>
    </div>
    <!-- 健康管理公众号平台 -->
    <div class="healthy_public">
      <h3 class="tit">健康管理公众号平台</h3>
      <div class="content_box">
        <div class="left">
          <img src="../assets/img/service/healthy_public_img.gif" alt="" />
        </div>
        <div class="right">
          <p>平台以健康服务为中心，对接睿见科技智慧核保、理赔系统，为客户提供一站式健康服务。</p>
          <span>扫码立即体验</span>
          <img src="../assets/img/service/healthy_public_ma.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 01 健康管家 -->
    <div class="page01 commonBox">
      <div class="left">
        <!-- <span class="common_num"></span> -->
        <img src="../assets/icon/service/num01.png" alt="" class="common_num" />
        <span class="common_big_tit">健康管家</span>
        <span class="smail_tit">健康档案</span>
        <p class="text">用户可登录健康管理平台建立自己的个人电子健康档案，档案内容包含基本信息、身体指标、既往病史、家族病史、过敏史、生活习惯等，可填写自己的相关信息。</p>
        <img src="../assets/img/service/healthy_page01_left.gif" alt="" class="img" />
      </div>
      <div class="right">
        <img src="../assets/img/service/healthy_page01_right.gif" alt="" />
        <span class="tit">智能专属个人健康评估报告</span>
        <p class="text">
          当健康方案各个模块的完成度为100%时，平台将会个性化生成个人专属健康评估报告。健康评估报告将会根据用户提供的档案信息，根据用户的性别不同，分别从身体指标、既往/家族病史、健康生活习惯、睡眠及心理这四个维度为用户提供一些专业医学建议，也会提供体检机构及医疗机构的入口，方便用户直接在线问诊及预约体检套餐等。
        </p>
      </div>
    </div>
    <!-- 02 居家监测指标 -->
    <div class="page02 commonBox">
      <div class="left">
        <img src="../assets/icon/service/num02.png" alt="" class="common_num" />
        <span class="common_big_tit">居家监测指标</span>
        <p class="text">
          目前平台提供血压、心率、BMI、血糖、糖化血红蛋白、血氧饱和度、心电图这7项居家监测指标的测量入口及结果分析功能，针对血压、心率、BMI的有效时段记录监控还可智能切换监测模式（比如血压从每天早晚各监测一次智能切换至每周监测2次），所有监测指标均支持第三方IOT设备自动录入数据及手动输入数据两种记录模式。
        </p>
      </div>
      <div class="right">
        <img src="../assets/img/service/healthy_page02_img.png" alt="" />
      </div>
    </div>
    <!-- 03 复诊管理 -->
    <div class="page03 commonBox">
      <img src="../assets/icon/service/num03.png" alt="" class="common_num" />
      <span class="common_big_tit">复诊管理</span>
      <div class="contentBox">
        <div class="box">
          <div class="item">
            <span>复诊时间提示</span>
            <p>用户输入自己最近一次就诊时间之后，平台将会根据疾病的种类以及相关指标是否达标而计算出用户下次复诊时间，并提示用户尽快复诊。</p>
          </div>
          <div class="item">
            <span>院前/院内就诊准备事项提示及上传</span>
            <p>就诊完成后，用户可上传报告至平台，方便下次复诊时医生可查看。</p>
          </div>
          <div class="item">
            <span>历史用药记录</span>
            <p>可添加历史用药</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 04 健康生活小贴士 -->
    <div class="page04 commonBox">
      <div class="left">
        <img src="../assets/icon/service/num04.png" alt="" class="common_num" />
        <span class="common_big_tit">健康生活小贴士</span>
        <p>
          平台根据各种疾病的注意事项，由专业的医学顾问团队贴心地为用户提供个性化健康生活小贴士，主要涵盖内容有戒烟控酒、膳食结构、运动方案及情绪管理建议等专业的医学常识。还有专门针对糖尿病患者的膳食小管家。
        </p>
      </div>
      <div class="right">
        <img src="../assets/img/service/healthy_page04_img.gif" alt="" />
      </div>
    </div>
    <!-- 05 智能理赔及核保服务 -->
    <div class="page05 commonBox">
      <img src="../assets/icon/service/num05.png" alt="" class="common_num" />
      <span class="common_big_tit">智能理赔及核保服务</span>
      <div class="contentBox">
        <div class="box">
          <div class="item">
            <img src="../assets/img/service/healthy_page05_img1.png" alt="" />
            <span>理赔服务</span>
            <p>一站式理赔资料报案服务，提交身份验证、理赔资料上传、信息确认，您就可以在历史赔案查询中查看您的理赔进度，已结案、审核中、报案失败，案件状态一看便知。</p>
          </div>
          <div class="item">
            <img src="../assets/img/service/healthy_page05_img2.png" alt="" />
            <span>我的保单</span>
            <p>可快速查看自己所有状态下的保单及保单详情，包含有效保单、已终止保单、可续保保单、待处理保单。</p>
          </div>
          <div class="item">
            <img src="../assets/img/service/healthy_page05_img3.png" alt="" />
            <span>投保查查</span>
            <p>想要投保，却不知道自己达不达标，投保查查帮您解决，多个问卷，选择自己想了解的，回答完问题便可实时获得投保风险评估结果。</p>
          </div>
          <div class="item">
            <img src="../assets/img/service/healthy_page05_img4.png" alt="" />
            <span>病历夹</span>
            <p>随时添加就诊的诊断病历，就医医疗票据、处方、诊断报告、体检报告等多种资料，上传保存，再也不用担心资料丢失、损坏等问题。需要这些资料时打开病历夹就可查看。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
// 专业团队
.professional_team {
  height: 986px;
  background-color: #fff;
  box-sizing: border-box;
  overflow: hidden;
  .titOne {
    margin-top: 136px;
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 40px;
    text-align: center;
  }
  .titTwo {
    display: block;
    margin-top: 14px;
    height: 24px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #788398;
    line-height: 24px;
    text-align: center;
  }
  .content_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 166px;
    .item {
      margin-top: 100px;
      width: 500px;
      img {
        display: block;
        margin: 0 auto;
        height: 84px;
        width: 84px;
      }
      .title {
        display: block;
        margin: 32px auto 0;
        height: 30px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #212632;
        line-height: 30px;
        text-align: center;
      }
      .text {
        margin-top: 24px;
        width: 500px;
        height: 72px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #788398;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
// 健康管理公众平台
.healthy_public {
  height: 874px;
  background: url('../assets/img/service/healthy_public_bg.png') no-repeat;
  background-size: cover;
  overflow: hidden;
  .tit {
    // width: 252px;
    margin-top: 120px;
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 40px;
    text-align: center;
  }
  .content_box {
    margin-top: 136px;
    padding: 0 130px 0 212px;
    overflow: hidden;
    .left {
      position: relative;
      float: left;
      width: 450px;
      height: 578px;
      img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
    .right {
      float: right;
      margin-top: 104px;
      width: 420px;

      overflow: hidden;
      vertical-align: top;
      p {
        display: inline-block;
        width: 420px;
        height: 80px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #788398;
        line-height: 40px;
      }
      span {
        display: block;
        margin-top: 104px;
        height: 30px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #212632;
        line-height: 30px;
      }
      img {
        margin-top: 40px;
        width: 160px;
        height: 160px;
      }
    }
  }
}
.commonBox {
  padding: 134px 130px 0;
  box-sizing: border-box;
  .common_num {
    display: block;
    height: 56px;
    width: 68px;
  }
  .common_big_tit {
    display: block;
    margin-top: 66px;
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 40px;
  }
}
// 01 健康管家
.page01 {
  height: 1040px;
  .left {
    float: left;
    width: 518px;
    span {
      display: block;
    }
    .smail_tit {
      margin-top: 100px;
      // width: 80px;
      height: 30px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #212632;
      line-height: 30px;
    }
    .text {
      margin-top: 24px;
      // width: 518px;
      height: 56px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #788398;
      line-height: 28px;
    }
    .img {
      margin-top: 144px;
      margin-left: 24px;
      width: 450px;
      height: 254px;
    }
  }
  .right {
    // display: inline-block;
    float: right;
    // margin-left: 144px;
    width: 518px;
    vertical-align: top;
    img {
      margin-top: 150px;
      margin-left: 22px;
      width: 450px;
      height: 284px;
    }
    .tit {
      display: block;
      margin-top: 158px;
      height: 30px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #212632;
      line-height: 30px;
    }
    .text {
      margin-top: 24px;
      height: 140px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #788398;
      line-height: 28px;
    }
  }
}
// 02 居家监测指标
.page02 {
  height: 768px;
  background: url('../assets/img/service/healthy_page02_bg.png') no-repeat;
  background-size: cover;
  .left {
    float: left;
    width: 246px;
    .common_big_tit {
      color: #ffffff;
    }
    .text {
      margin-top: 100px;
      // width: 246px;
      height: 252px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      opacity: 0.7;
    }
  }
  .right {
    float: right;
    // margin-left: 148px;
    img {
      margin-top: 154px;
      width: 786px;
      height: 368px;
    }
  }
}
// 03 复诊管理
.page03 {
  height: 724px;
  background: url('../assets/img/service/healthy_page03_bg.png') no-repeat;
  background-size: cover;
  .contentBox {
    margin-top: 100px;
    overflow: hidden;
    .box {
      width: 1200px;
      overflow: hidden;
    }
    .item {
      float: left;
      margin-right: 20px;
      width: 380px;
      height: 208px;
      background: #ffffff;
      border: 1px solid #edf0f5;
      border-top: 2px solid #8f98a9;
      box-sizing: border-box;
      &:nth-child(3) {
        margin-right: 0;
      }
      span {
        display: block;
        margin-top: 26px;
        padding-left: 28px;
        height: 30px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #212632;
        line-height: 30px;
      }
      p {
        margin: 36px auto 0;
        width: 324px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #788398;
        line-height: 28px;
      }
    }
  }
}
// 04 健康生活小贴士
.page04 {
  height: 600px;
  .left {
    float: left;
    width: 583px;
    p {
      margin-top: 100px;
      // width: 583px;
      height: 84px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #788398;
      line-height: 28px;
    }
  }
  .right {
    float: right;
    img {
      margin-top: 120px;
      width: 408px;
      height: 240px;
    }
  }
}
// 05 智能理赔及核保服务
.page05 {
  height: 808px;
  background: url('../assets/img/service/healthy_page05_bg.png') no-repeat;
  background-size: cover;
  .contentBox {
    margin-top: 100px;
    overflow: hidden;
    .box {
      width: 1250px;
      overflow: hidden;
      .item {
        float: left;
        margin-right: 58px;
        width: 250px;
        &:nth-child(4) {
          margin-right: 0;
        }
        img {
          display: block;
          margin: 0 auto;
          width: 108px;
          height: 108px;
        }
        span {
          display: block;
          margin-top: 14px;
          text-align: center;
          height: 30px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 30px;
        }
        p {
          margin-top: 44px;
          height: 120px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #788398;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
