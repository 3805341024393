<template>
  <div class="footer">
    <div class="footOne contanier">
      <div class="left item">
        <p>北京中科睿见科技有限公司</p>
        <span>BEIJING SMART INSIGHT TECHNOLOGY CO., LTD</span>
      </div>
      <div class="joinUs right">
        <img src="../assets/icon/footer/joinUs.png" alt="" />
        <div class="item">
          <p>加入我们</p>
          <span class="span">带你走向人生巅峰</span>
        </div>
      </div>
      <div class="followUs right">
        <img src="../assets/icon/footer/followUs.png" alt="" />
        <div class="item">
          <p>关注我们</p>
          <span class="span">全方位了解中科睿见</span>
        </div>
      </div>
    </div>
    <div class="footTwo contanier">
      <dl>
        <dt>产品服务</dt>
        <dd @click="goPage(0, index)" v-for="(item, index) in aInfo[0].name" :key="index">{{ item }}</dd>
      </dl>
      <dl>
        <dt>员工</dt>
        <dd @click="goPage(1, index)" v-for="(item, index) in aInfo[1].name" :key="index">{{ item }}</dd>
      </dl>
      <dl>
        <dt>关于我们</dt>
        <dd @click="goPage(2, index)" v-for="(item, index) in aInfo[2].name" :key="index">{{ item }}</dd>
      </dl>
      <dl>
        <dt>联系我们</dt>
        <dd @click="goPage(3, index)" v-for="(item, index) in aInfo[3].name" :key="index">{{ item }}</dd>
      </dl>
      <div class="img_joinUs">
        <img src="../assets/img/component/QRCode_joinUs.png" alt="" />
        <div>扫码投递简历</div>
      </div>
      <div class="img_followUs">
        <img src="../assets/img/component/QRCode_followUs.png" alt="" />
        <div>微信公众号</div>
      </div>
      <div class="douyin">
        <img src="../assets/img/component/douyin.png" alt="" />
        <div>
          抖音号<br />
          39757538668
        </div>
      </div>
    </div>
    <div class="contanier">
      <div class="footThree">
        <p>©北京中科睿见科技有限公司版权所有 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="go" @click="goGXB">京ICP备18059566号-2</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      aInfo: [
        {
          routerName: 'Service',
          name: ['智能核保', '智慧理赔', '健康管理'],
          anchor: [1, 2, 3]
        },
        {
          routerName: 'Member',
          name: ['员工活动', '办公环境'],
          anchor: ['staff', 'office']
        },
        {
          routerName: 'AboutUs',
          name: ['公司简介', '发展历程', '社会价值'],
          anchor: ['company', 'history', 'social']
        },
        {
          routerName: 'ContactUs',
          name: ['职位投递', '公司地址'],
          anchor: ['wait', 'address']
        }
      ]
    }
  },
  methods: {
    goPage(i, index) {
      console.log(i, index)
      // 1、判断是否是当前页面
      if (this.$route.name !== this.aInfo[i].routerName) {
        // 1.1 非当前页面，跳转+锚点
        // 2、判断是否是 service
        if (this.aInfo[i].routerName === 'Service') {
          // 2.1 是service页面
          this.$router.push({ name: 'Service', params: { state: 1, index: index } })
        } else {
          // 2.2 不是service页面
          this.$router.push({ name: this.aInfo[i].routerName, params: { anchor: this.aInfo[i].anchor[index] } })
        }
        console.log(this.aInfo[i].routerName)
      } else {
        // 1.2 当前页面，组件传锚点
        if (this.aInfo[i].routerName === 'Service') {
          this.$emit('goAnchor', index)
        } else {
          this.$emit('goAnchor', this.aInfo[i].anchor[index])
        }
      }
    },
    goGXB() {
      location.href = 'https://beian.miit.gov.cn/'
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  height: 344px;
  background-color: #252b3a;
  .footOne {
    height: 112px;
    background-color: #212635;
    overflow: hidden;
    .left {
      margin-top: 31px;
      display: inline-block;
    }
    .right {
      display: flex;
      align-items: center;
      margin-left: 128px;
      height: 100%;
      img {
        margin-right: 12px;
        width: 36px;
        height: 36px;
      }
    }
    .joinUs {
      float: right;
    }
    .followUs {
      float: right;
    }

    .item {
      display: inline-block;
      p {
        height: 28px;
        font-size: 20px;
        font-weight: 500;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #ffffff;
        line-height: 28px;
      }
      span {
        height: 17px;
        font-size: 12px;
        font-weight: 500;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #ffffff;
        line-height: 17px;
        opacity: 0.6;
      }
      .span {
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
  }
  .footTwo {
    height: 161px;
    overflow: hidden;
    dl {
      float: left;
      width: 176px;
      dt {
        margin: 20px 0 21px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
      }
      dd {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 27px;
        opacity: 0.7;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          opacity: 1;
        }
      }
    }
    .img_followUs,
    .img_joinUs,
    .douyin {
      float: right;
      margin-top: 19px;
      width: 100px;
      height: 100px;
      div {
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 14px;
        text-align: center;
        opacity: 0.7;
      }
      img {
        margin-bottom: 8px;
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
    }
    .img_joinUs,
    .img_followUs {
      margin-left: 40px;
    }
  }
  .footThree {
    border-top: 1px solid #353d51;
    p {
      // width: 391px;
      margin-top: 23px;
      height: 27px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 26px;
      opacity: 0.7;
      span {
        cursor: pointer;
      }
      soan:hover {
        color: #fff;
      }
    }
  }
}
</style>
