<template>
  <div class="wrap">
    <div class="page3 contanier">
      <h3>采用云平台的方式进行部署</h3>
      <p>方便与客户进行开发和联调</p>
      <ul>
        <li><img src="../assets/img/service/pg3_saas.png" alt="" /><span>SaaS服务交付</span></li>
        <li><img src="../assets/img/service/pg3_intelligence.png" alt="" /><span>智能终端访问</span></li>
        <li><img src="../assets/img/service/pg3_docker.png" alt="" /><span>Docker交付模型组</span></li>
        <li><img src="../assets/img/service/pg3_object.png" alt="" /><span>对象存储技术</span></li>
        <li><img src="../assets/img/service/pg3_customer.png" alt="" /><span>客户端管理</span></li>
        <li><img src="../assets/img/service/pg3_data.png" alt="" /><span>数据分析</span></li>
      </ul>
    </div>
    <div class="page4 contanier">
      <div class="contentBox">
        <div class="left">
          <p class="title">四个功能模块</p>
          <i></i>
          <p class="text">通过对投保人健康材料进行全面的分析，形成每个人的健康风险量化指标。</p>
        </div>
        <ul class="right">
          <li>
            <span>核保材料审核</span>
            <p>核保人员将核保资料上传到睿见智能核保系统中，系统通过全息智能识别技术，对核保资料中文本进行技术解构。</p>
          </li>
          <li>
            <span>深度学习引擎</span>
            <p>通过自然语言处理技术和睿见医学知识图谱，识别资料中的核保元素，系统能够识别身份信息、普通体检报告、门诊病例、住院病例、检查报告、病理报告、放射性报告等十几种医疗资料。</p>
          </li>
          <li>
            <span>核保结论输出</span>
            <p>将识别后的核保元素与再保核保手册中的条款进行匹配，综合不同的疾病风险因素，输出最终核保结果。</p>
          </li>
          <li>
            <span>智能医疗分析平台</span>
            <p>不同的核保产品对同样疾病核保风险因素，有不同的核保规则。基于产品级风险控制配置引擎系统，能够结合核保产品和险种，给出对应的风险权重，最终实现产品级的核保。</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="page5 contanier">
      <h3>四大特点</h3>
      <ul>
        <li>
          <div><img src="../assets/icon/service/pg5_distinguish.png" alt="" /><span>智能识别</span></div>
          <img src="../assets/icon/service/dot.png" alt="" class="dot" />
          <p>识别准确率高达99.7%</p>
        </li>
        <li>
          <div><img src="../assets/icon/service/pg5_standard.png" alt="" /><span>规范流程</span></div>
          <img src="../assets/icon/service/dot.png" alt="" class="dot" />
          <p>杜绝传统核保方式中核保员主观判断，导致核保结果缺乏一致性；避免了用户理解偏差</p>
        </li>
        <li>
          <div><img src="../assets/icon/service/pg5_resources.png" alt="" /><span>节省资源</span></div>
          <img src="../assets/icon/service/dot.png" alt="" class="dot" />
          <p>通过后台系统的设计，缩短用户核保时间，解决人工核保多次收集资料、重复核保等问题，节省了大量的人力成本</p>
        </li>
        <li>
          <div><img src="../assets/icon/service/pg5_individualization.png" alt="" /><span>提供个性化解决方案</span></div>
          <img src="../assets/icon/service/dot.png" alt="" class="dot" />
          <p>提供个性化解决方案，可以根据体检报告、病理报告等预测未来的身体状况及费用等，为保险公司及客户提供个性化保险方案</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
// .wrap {
// //   height: 2000px;
// //   background-color: pink;
// }
.page3 {
  padding-top: 139px;
  height: 565px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  h3 {
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 40px;
  }
  p {
    margin-top: 14px;
    height: 24px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #788398;
    line-height: 24px;
  }
  ul {
    display: flex;
    justify-content: space-around;
    margin-top: 87px;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        margin-bottom: 19px;
        width: 84px;
        height: 84px;
      }
      span {
        // width: 103px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #010f36;
        line-height: 22px;
      }
    }
  }
}
.page4 {
  width: 100%;
  height: 640px;
  background: url('../assets/img/service/servicebg_page4@2x.png') no-repeat;
  background-size: cover;
  overflow: hidden;
  .contentBox {
    display: flex;
    margin-top: 100px;
    height: 440px;
    .left {
      width: 406px;
      height: 100%;
      background: url('../assets/img/service/four_modular_bg.png') no-repeat;
      background-size: cover;
      text-align: center;
      overflow: hidden;
      .title {
        margin-top: 142px;
        // width: 168px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 40px;
      }
      i {
        display: inline-block;
        margin-top: 20px;
        width: 18px;
        height: 4px;
        background: #ffffff;
      }
      .text {
        margin: 36px auto 0;
        width: 258px;
        height: 48px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
    .right {
      flex: 1;
      li {
        float: left;
        width: 50%;
        height: 220px;
        border: 1px solid #edf0f5;
        box-sizing: border-box;
        span {
          display: block;
          padding-left: 28px;
          // width: 120px;
          height: 80px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 80px;
          box-sizing: inherit;
          border-bottom: 1px solid #edf0f5;
          background-color: #fbfbfb;
        }
        p {
          padding: 22px 28px;
          // width: 330px;
          height: 140px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #788398;
          line-height: 24px;
          background-color: #ffffff;
          box-sizing: inherit;
        }
      }
    }
  }
}
.page5 {
  width: 100%;
  height: 708px;
  overflow: hidden;
  h3 {
    margin: 136px auto 76px;
    width: 112px;
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 40px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    li {
      width: 280px;
      height: 340px;
      background: #fbfbfb;
      border: 1px solid #edf0f5;
      overflow: hidden;
      div {
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
        margin-top: 80px;
        transition: all 0.5s;

        img {
          margin-bottom: 24px;
          width: 60px;
          height: 60px;
        }
        span {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 30px;
        }
      }

      .dot {
        display: block;
        margin: 82px auto 0;
        width: 28px;
        height: 4px;
        transition: all 0.3s;
      }

      p {
        // display: none;
        margin: 40px auto 0;
        width: 218px;
        height: 96px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #788398;
        line-height: 24px;
        opacity: 0;
        transition: all 0.5s;
      }

      &:hover {
        // box-shadow: 0 4px 16px 0 rgb(209, 220, 232, 63%);
        // border: 1px solid transparent;
        div {
          margin-top: 47px;
        }
        .dot {
          margin-top: 24px;
        }
        p {
          opacity: 1;
        }
      }
    }
  }
}
</style>
